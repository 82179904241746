// extracted by mini-css-extract-plugin
export var aniSpin = "Img-module--ani-spin--Be22e";
export var black = "#000";
export var el = "Img-module--el--pO6BW";
export var img = "Img-module--img--oiQKE";
export var largeDesktop = "1400px";
export var largePhone = "480px";
export var mediumDesktop = "1300px";
export var placeholder = "Img-module--placeholder--EFvcK";
export var smallDesktop = "1100px";
export var smallPhone = "320px";
export var tabletLandscape = "1024px";
export var tabletPortrait = "768px";
export var white = "#fff";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";