import { getSlug } from '@/utils/getSlug'
import { get } from '@/utils/get'
import { getRichText } from '@/prismic/utils/getRichText'
import { getImage } from '@/prismic/utils/getImage'
import { getLink } from '@/prismic/utils/getLink'

/**
 * Process prismic slice data that can be passed to components as props
 *
 * @param {array} data
 * @param {object} location
 */

export const sectionSerializer = (slices, events) => {
  const sections = []

  slices.forEach((slice, sliceIndex) => {
    if (slice) {
      // section object
      const section = {
        type: slice.slice_type,
        id: slice.id,
      }

      section.id = `section-${sliceIndex}`

      // restructure 'primary' object prop to root
      if (slice.primary) {
        Object.keys(slice.primary).forEach((key) => {
          if (Object.prototype.hasOwnProperty.call(slice.primary, key)) {
            slice[key] = slice.primary[key]
          }
        })
        delete slice.primary
      }

      section.uid = slice.uid

      // menu
      if (slice.slice_type === 'menu') {
        section.footer = getRichText(slice, 'footer.raw')
        if (get(slice, 'footer_link')) {
          section.footerLink = getLink(
            slice.footer_link,
            slice.footer_link_label || 'Learn more'
          )
        }
        section.menus = []
        slice.items.forEach((node) => {
          const menu = get(node, 'menu.document')
          if (menu) {
            const menuLeft = { id: 'menu-left', sections: [] }
            const menuRight = { id: 'menu-right', sections: [] }
            menu.data.body.forEach((menuSection, menuSliceIndex) => {
              const sectionOutput = {
                id: menuSection.id,
                type: menuSection.slice_type,
                title: menuSection.primary.heading,
              }

              if (menuSection.slice_type === 'menu_section') {
                sectionOutput.items = []
                menuSection.items.forEach((item, index) => {
                  sectionOutput.items.push({
                    id: `${sliceIndex}-menu-${index}`,
                    title: item.title,
                    subtitle: item.subtitle,
                    price: item.price,
                    description: getRichText(item, 'description.raw'),
                  })
                })
              } else if (menuSection.slice_type === 'menu_text') {
                sectionOutput.text = getRichText(
                  menuSection,
                  'primary.content.raw'
                )
              } else if (menuSection.slice_type === 'menu_text_2_column') {
                const subColumnLeft = {
                  id: `${sliceIndex}-columnLeft-${menuSliceIndex}`,
                  sections: [],
                }
                const subColumnRight = {
                  id: `${sliceIndex}-columnRight-${menuSliceIndex}`,
                  sections: [],
                }

                menuSection.items.forEach((colunn, index) => {
                  const subSectionOutput = {
                    id: `${sliceIndex}-column-${index}`,
                    heading: colunn.heading,
                    text: getRichText(colunn, 'content.raw'),
                  }

                  const position = get(colunn, 'desktop_position')

                  if (position === 'Right Column') {
                    subColumnRight.sections.push(subSectionOutput)
                  } else {
                    subColumnLeft.sections.push(subSectionOutput)
                  }
                })

                sectionOutput.columns = [subColumnLeft, subColumnRight]
              } else if (menuSection.slice_type === 'menu_callout') {
                sectionOutput.heading = menuSection.primary.heading
                sectionOutput.subtitle = menuSection.primary.subtitle
                sectionOutput.price = menuSection.primary.price
              }

              const position = get(menuSection, 'primary.desktop_position')
              if (position === 'Right Column') {
                menuRight.sections.push(sectionOutput)
              } else {
                menuLeft.sections.push(sectionOutput)
              }
            })

            section.menus.push({
              id: menu.id,
              title: menu.data.title,
              sections: [menuLeft, menuRight],
              emoji: getSlug(menu.data.emoji),
            })
          }
        })
      }
      // cta
      else if (slice.slice_type === 'cta') {
        section.heading = slice.heading_text
        section.children = getRichText(slice, 'content.raw')
        if (get(slice, 'link')) {
          section.link = getLink(slice.link, slice.link_label || 'Learn more')
        }
      }

      // image layout
      else if (slice.slice_type === 'image_layout') {
        section.images = []
        slice.items.forEach((item, index) => {
          section.images.push({
            ...getImage(item, 'image', 'fluid'),
            id: `image_layout_${sliceIndex}_${index}`,
          })
        })
      }

      // contact
      else if (slice.slice_type === 'contact') {
        section.heading = get(slice, 'heading.text')
        section.details = getRichText(slice, 'contact_details.raw')
        section.video = get(slice, 'video.url')
        section.image = getImage(slice, 'image', 'fluid')
        section.openingHours = [
          {
            id: 'hours_tuesday',
            day: 'Tuesday',
            hours: slice.hours_tuesday,
          },
          {
            id: 'hours_wednesday',
            day: 'Wednesday',
            hours: slice.hours_wednesday,
          },
          {
            id: 'hours_thursday',
            day: 'Thursday',
            hours: slice.hours_thursday,
          },
          {
            id: 'hours_friday',
            day: 'Friday',
            hours: slice.hours_friday,
          },
          {
            id: 'hours_saturday',
            day: 'Saturday',
            hours: slice.hours_saturday,
          },
          {
            id: 'hours_sunday',
            day: 'Sunday',
            hours: slice.hours_sunday,
          },
          {
            id: 'hours_monday',
            day: 'Monday',
            hours: slice.hours_monday,
          },
        ]
      }

      // events
      else if (slice.slice_type === 'events') {
        section.events = events
        section.noEventsMessage = getRichText(slice, 'no_events_message.raw')
      }

      // add to output array
      sections.push(section)
    }
  })

  return sections
}
