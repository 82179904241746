import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from '@/components/RichText'
import { Btn } from '@/components/Btn'
import * as styles from './Callout.module.scss'

const Callout = ({ children, link }) => {
  return (
    <div className={styles.Callout}>
      <div className={styles.footer__wrap}>
        <span className={styles.footer__text}>
          {children && <RichText>{children}</RichText>}
        </span>
        {link && (
          <span className={styles.footer__btn}>
            <Btn to={link.url} target={link.target}>
              {link.title}
            </Btn>
          </span>
        )}
      </div>
    </div>
  )
}

Callout.propTypes = {
  children: PropTypes.node,
  link: PropTypes.object,
}

export { Callout }
