// extracted by mini-css-extract-plugin
export var aniSpin = "Thumb-module--ani-spin--SjInA";
export var black = "#000";
export var btn = "Thumb-module--btn--Rls1W";
export var btn__inner = "Thumb-module--btn__inner--aTnD-";
export var grid = "Thumb-module--grid--bTC1t";
export var heading = "Thumb-module--heading--7yKxp";
export var image = "Thumb-module--image--iNsBl";
export var largeDesktop = "1400px";
export var largePhone = "480px";
export var mediumDesktop = "1300px";
export var smallDesktop = "1100px";
export var smallPhone = "320px";
export var tabletLandscape = "1024px";
export var tabletPortrait = "768px";
export var text = "Thumb-module--text--0LlFQ";
export var white = "#fff";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";