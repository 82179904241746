import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { motion } from 'framer-motion'
import { Container } from '@/components/Container'
import { RichText } from '@/components/RichText'
import { BtnEmoji } from '@/components/BtnEmoji'
import { Section } from '@/components/Section'
import { Callout } from '@/components/Callout'
import { ScrollContext } from '@/components/SmoothScroll'
import * as styles from './Menu.module.scss'

const Menu = ({ menus, uid, footer, footerLink }) => {
  const [activeMenu, setActiveMenu] = useState(menus[0])
  const { smoothScroll } = useContext(ScrollContext)
  const handleSelect = (index) => {
    setActiveMenu(menus[index])
    // update scroll controller with new height
    setTimeout(() => smoothScroll.update(), 100)
  }

  return (
    <Section
      className={styles.menu}
      id={uid}
      intersectionClassName={styles.intersection}
    >
      <Container className={styles.container}>
        <div className={styles.inner}>
          {menus.length > 1 && (
            <header className={styles.header}>
              {menus.map((menu, index) => {
                return (
                  <div className={styles.header__item} key={`btn-${menu.id}`}>
                    <BtnEmoji
                      onClick={() => handleSelect(index)}
                      emoji={menu.emoji}
                    >
                      {menu.title}
                    </BtnEmoji>
                    <motion.div
                      className={styles.header__circle}
                      animate={{ opacity: activeMenu.id === menu.id ? 1 : 0 }}
                    />
                  </div>
                )
              })}
            </header>
          )}
          <div className={styles.sections}>
            {activeMenu.sections.map((column) => (
              <div key={column.id} className={styles.column}>
                {column.sections.map((section) => {
                  if (section.type === 'menu_callout') {
                    return (
                      <div key={section.id} className={styles.section}>
                        <div className={styles.callout}>
                          <div className={styles.callout__inner}>
                            <div className={styles.callout__heading}>
                              {section.heading}
                            </div>
                            <div className={styles.callout__subtitle}>
                              {section.subtitle}
                            </div>
                            <div className={styles.callout__price}>
                              {section.price}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  return (
                    <div key={section.id} className={styles.section}>
                      {section.title && (
                        <h3 className={styles.section__title}>
                          {section.title}
                        </h3>
                      )}
                      {section.text && (
                        <div className={styles.section__text}>
                          {section.text}
                        </div>
                      )}
                      {section.columns && !!section.columns.length && (
                        <div className={styles.section__columns}>
                          {section.columns.map((subColumn) => (
                            <div
                              key={subColumn.id}
                              className={styles.section__columns__i}
                            >
                              {subColumn.sections.map((subSection) => (
                                <div
                                  key={subSection.id}
                                  className={styles.section}
                                >
                                  <h3 className={styles.section__title}>
                                    {subSection.heading}
                                  </h3>
                                  {subSection.text && (
                                    <div className={styles.section__text}>
                                      {subSection.text}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          ))}
                        </div>
                      )}
                      {section.items && !!section.items.length && (
                        <div className={styles.items}>
                          {section.items.map((item) => (
                            <div key={item.id} className={styles.item}>
                              <h4 className={styles.item__heading}>
                                <span className={styles.item__heading__title}>
                                  {item.title}
                                </span>
                                {item.subtitle && (
                                  <span className={styles.item__subtitle}>
                                    {item.subtitle}
                                  </span>
                                )}
                                <span className={styles.item__price}>
                                  {item.price}
                                </span>
                              </h4>
                              {item.description && (
                                <div className={styles.item__description}>
                                  <RichText>{item.description}</RichText>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            ))}
          </div>
        </div>
        {(footer || footerLink) && (
          <div className={styles.footer}>
            <Callout link={footerLink}>{footer}</Callout>
          </div>
        )}
      </Container>
    </Section>
  )
}

Menu.defaultProps = {
  menus: [],
  uid: undefined,
  footer: undefined,
  footerLink: undefined,
}

Menu.propTypes = {
  menus: PropTypes.arrayOf(PropTypes.object),
  uid: PropTypes.string,
  footer: PropTypes.node,
  footerLink: PropTypes.object,
}

export { Menu }
