import React from 'react'
import { useInView } from 'react-intersection-observer'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as styles from './Section.module.scss'

const Section = ({
  children,
  className,
  paddingTop = true,
  paddingBottom = true,
  prev,
  next,
  id,
  intersectionClassName,
}) => {
  const classes = classNames(styles.el, className)
  const intersectionEnabled = !!id

  const { ref } = useInView({
    skip: !intersectionEnabled,
    threshold: 0,
    onChange: (inView) => {
      if (inView && id) {
        window.history.pushState(null, null, `#${id}`)
      }
    },
  })

  return (
    <section
      className={classes}
      data-section
      data-padding-top={paddingTop}
      data-padding-bottom={paddingBottom}
      data-next={next}
      data-prev={prev}
      id={id}
    >
      {intersectionEnabled && (
        <div
          className={classNames(styles.intersection, intersectionClassName)}
          ref={ref}
        />
      )}
      {children}
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  paddingTop: PropTypes.bool,
  paddingBottom: PropTypes.bool,
  prev: PropTypes.string,
  next: PropTypes.string,
  id: PropTypes.string,
}

export { Section }
