// extracted by mini-css-extract-plugin
export var aniSpin = "Events-module--ani-spin--Z0KXF";
export var black = "#000";
export var el = "Events-module--el--RYNJw";
export var el__allora = "Events-module--el__allora--8s1bN";
export var el__bannerOffset = "Events-module--el__bannerOffset--4Fzfa";
export var el__item = "Events-module--el__item--Pwjj-";
export var el__section = "Events-module--el__section--XlmyR";
export var el__section__title = "Events-module--el__section__title--ifmFr";
export var largeDesktop = "1400px";
export var largePhone = "480px";
export var mediumDesktop = "1300px";
export var noEvents = "Events-module--noEvents--CR8U6";
export var noEvents__heading = "Events-module--noEvents__heading--V1+nG";
export var noEvents__message = "Events-module--noEvents__message--Srwj9";
export var smallDesktop = "1100px";
export var smallPhone = "320px";
export var tabletLandscape = "1024px";
export var tabletPortrait = "768px";
export var white = "#fff";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";