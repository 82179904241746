// extracted by mini-css-extract-plugin
export var aniSpin = "Menu-module--ani-spin--fwxhc";
export var black = "#000";
export var callout = "Menu-module--callout--vFusg";
export var callout__heading = "Menu-module--callout__heading--0tU1T";
export var callout__inner = "Menu-module--callout__inner--96OzN";
export var callout__price = "Menu-module--callout__price--3LSVi";
export var callout__subtitle = "Menu-module--callout__subtitle--WzeiF";
export var column = "Menu-module--column--kbnJo";
export var container = "Menu-module--container--ccoTv";
export var footer = "Menu-module--footer--N6DZe";
export var header = "Menu-module--header--mAFNE";
export var header__circle = "Menu-module--header__circle--vKgUK";
export var header__item = "Menu-module--header__item--O3F5d";
export var inner = "Menu-module--inner--llDXv";
export var intersection = "Menu-module--intersection--uyCgJ";
export var item = "Menu-module--item--RHe3Y";
export var item__description = "Menu-module--item__description--e9gWN";
export var item__heading = "Menu-module--item__heading--uXw9Y";
export var item__heading__title = "Menu-module--item__heading__title--DXNtM";
export var item__price = "Menu-module--item__price--ue2RY";
export var item__subtitle = "Menu-module--item__subtitle--wB3Lf";
export var items = "Menu-module--items--swQoF";
export var largeDesktop = "1400px";
export var largePhone = "480px";
export var mediumDesktop = "1300px";
export var menu = "Menu-module--menu--1-Q-e";
export var section = "Menu-module--section--EASo3";
export var section__columns = "Menu-module--section__columns--I49ER";
export var section__columns__i = "Menu-module--section__columns__i--JLcIt";
export var section__text = "Menu-module--section__text--P9HXt";
export var section__title = "Menu-module--section__title--w+ekr";
export var sections = "Menu-module--sections--RDzBE";
export var smallDesktop = "1100px";
export var smallPhone = "320px";
export var tabletLandscape = "1024px";
export var tabletPortrait = "768px";
export var white = "#fff";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";