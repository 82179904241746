// extracted by mini-css-extract-plugin
export var aniSpin = "Contact-module--ani-spin--d5jlh";
export var black = "#000";
export var contact = "Contact-module--contact--extip";
export var contactInfo = "Contact-module--contactInfo--g4CkE";
export var contactInfo__richText = "Contact-module--contactInfo__richText--BXL6e";
export var container = "Contact-module--container--OkaMT";
export var details = "Contact-module--details--r1aZB";
export var header = "Contact-module--header--GdMwp";
export var image = "Contact-module--image--L4mAd";
export var image__inner = "Contact-module--image__inner--RmT2H";
export var image__sizer = "Contact-module--image__sizer---oIgv";
export var inner = "Contact-module--inner--p5fWz";
export var intersection = "Contact-module--intersection--v7T+X";
export var item = "Contact-module--item--m1ACD";
export var item__heading__price = "Contact-module--item__heading__price--vSKQO";
export var largeDesktop = "1400px";
export var largePhone = "480px";
export var marquee = "Contact-module--marquee--O0hwf";
export var marquee__inner = "Contact-module--marquee__inner--+4TBG";
export var mediumDesktop = "1300px";
export var openingHours = "Contact-module--openingHours--VC1fh";
export var openingHours__row = "Contact-module--openingHours__row--Df9YP";
export var section = "Contact-module--section--CCw85";
export var section__title = "Contact-module--section__title--BSVIM";
export var smallDesktop = "1100px";
export var smallPhone = "320px";
export var tabletLandscape = "1024px";
export var tabletPortrait = "768px";
export var video = "Contact-module--video--q8CiV";
export var video__inner = "Contact-module--video__inner--+MnyG";
export var video__sizer = "Contact-module--video__sizer--69n2W";
export var white = "#fff";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";