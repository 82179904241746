import React from 'react'
import PropTypes from 'prop-types'
import { SectionRouter } from '@/components/SectionRouter'

import { Contact } from '@/sections/Contact'
import { Cta } from '@/sections/Cta'
import { Events } from '@/sections/Events'
import { ImageLayout } from '@/sections/ImageLayout'
import { Menu } from '@/sections/Menu'

import * as styles from './Page.module.scss'

const Page = ({ sections }) => {
  return (
    <div className={styles.el}>
      <SectionRouter
        sections={sections}
        types={{
          contact: Contact,
          cta: Cta,
          events: Events,
          image_layout: ImageLayout,
          menu: Menu,
        }}
      />
    </div>
  )
}

Page.defaultProps = {
  sections: [],
}

Page.propTypes = {
  sections: PropTypes.array,
}

export default Page
