import React from 'react'
import PropTypes from 'prop-types'
import { RichText } from '@/components/RichText'
import { Container } from '@/components/Container'
import { Thumb } from '@/components/Thumb'
import { Section } from '@/components/Section'
import * as styles from './Events.module.scss'
import AlloraSvg from './images/allora.inline.svg'

const Events = ({ uid, events, hasBanner, noEventsMessage }) => (
  <Section className={styles.el} id={uid}>
    <Container>
      {events.length ? (
        <div className={hasBanner ? styles.el__bannerOffset : ''}>
          {events.map((event, index) => {
            return (
              <div className={styles.el__item} key={event.id}>
                {hasBanner && index === 0 && (
                  <div className={styles.el__allora}>
                    <AlloraSvg />
                  </div>
                )}
                <Thumb
                  image={event.image}
                  heading={event.title}
                  description={event.description}
                  link={event.link}
                />
              </div>
            )
          })}
        </div>
      ) : (
        <div className={styles.noEvents}>
          <div className={styles.noEvents__message}>
            <h3 className={styles.noEvents__heading}>Events</h3>
            <RichText>{noEventsMessage}</RichText>
          </div>
        </div>
      )}
    </Container>
  </Section>
)

Events.defaultProps = {
  uid: undefined,
  events: [],
  hasBanner: true,
  noEventsMessage: 'No events found, please check back later',
}

Events.propTypes = {
  uid: PropTypes.string,
  events: PropTypes.array,
  hasBanner: PropTypes.bool,
  noEventsMessage: PropTypes.node,
}

export { Events }
