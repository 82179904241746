// extracted by mini-css-extract-plugin
export var aniSpin = "Page-module--ani-spin--SS8EV";
export var black = "#000";
export var el = "Page-module--el--VmdEM";
export var largeDesktop = "1400px";
export var largePhone = "480px";
export var mediumDesktop = "1300px";
export var smallDesktop = "1100px";
export var smallPhone = "320px";
export var tabletLandscape = "1024px";
export var tabletPortrait = "768px";
export var white = "#fff";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";