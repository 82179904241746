import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import * as styles from './VideoBg.module.scss'

const VideoBg = ({ isPlaying, url }) => {
  return (
    <ReactPlayer
      className={styles.el}
      url={url}
      playing={isPlaying}
      width="100%"
      height="100%"
      controls={false}
      muted
      playsinline
      loop
    />
  )
}

VideoBg.defaultProps = {
  isPlaying: true,
  url: undefined,
}

VideoBg.propTypes = {
  isPlaying: PropTypes.bool,
  url: PropTypes.string,
}

export { VideoBg }
