import React from 'react'
import PropTypes from 'prop-types'
import * as styles from './BtnEmoji.module.scss'

// https://github.com/pladaria/react-emojione

import Wine from './images/1f377.png'
import Cocktail from './images/1f378.png'
import Pasta from './images/1f35d.png'
import Tomato from './images/1f345.png'

const EMOJIS = {
  'tomato-+-pasta': [
    { id: 'tomato', url: Tomato },
    { id: 'pasta', url: Pasta },
  ],
  'wine-+-cocktail': [
    { id: 'wine', url: Wine },
    { id: 'cocktail', url: Cocktail },
  ],
}

const BtnEmoji = ({ children, onClick, emoji, ...attributes }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={styles.el}
      {...attributes}
    >
      <span className={styles.el__text}>{children}</span>
      <span className={styles.el__emoji}>
        {EMOJIS[emoji].map((image) => (
          <span
            className={styles.el__emoji__image}
            key={image.id}
            style={{ backgroundImage: `url(${image.url})` }}
          />
        ))}
        <span />
      </span>
    </button>
  )
}

BtnEmoji.defaultProps = {
  children: undefined,
  onClick: () => {},
  emoji: undefined,
}

BtnEmoji.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  emoji: PropTypes.string,
}

export { BtnEmoji }
