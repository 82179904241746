import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { Callout } from '@/components/Callout'
import { Section } from '@/components/Section'
import * as styles from './Cta.module.scss'

const Cta = ({ children, link, heading, uid }) => {
  return (
    <Section id={uid} intersectionClassName={styles.intersection}>
      <Container>
        <div className={styles.inner}>
          {heading && <h3 className={styles.heading}>{heading}</h3>}
          <Callout link={link}>{children}</Callout>
        </div>
      </Container>
    </Section>
  )
}

Cta.propTypes = {
  heading: PropTypes.node,
  children: PropTypes.node,
  link: PropTypes.object,
  uid: PropTypes.string,
}

export { Cta }
