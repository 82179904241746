// extracted by mini-css-extract-plugin
export var aniSpin = "Cta-module--ani-spin--39Ooa";
export var black = "#000";
export var heading = "Cta-module--heading--24Fl0";
export var inner = "Cta-module--inner--OTff5";
export var intersection = "Cta-module--intersection--r0QEF";
export var largeDesktop = "1400px";
export var largePhone = "480px";
export var mediumDesktop = "1300px";
export var smallDesktop = "1100px";
export var smallPhone = "320px";
export var tabletLandscape = "1024px";
export var tabletPortrait = "768px";
export var white = "#fff";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";