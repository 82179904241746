import React from 'react'
import PropTypes from 'prop-types'
import { Container } from '@/components/Container'
import { Img } from '@/components/Img'
import { Section } from '@/components/Section'
import PatternSvg from './images/pattern.inline.svg'
import * as styles from './ImageLayout.module.scss'

const speeds = [-2, 2, 1, 2, -1, 2, 1]

const ImageLayout = ({ uid, images }) => {
  return (
    <Section className={styles.el} id={uid}>
      <Container>
        <div className={styles.el__inner}>
          <div className={styles.el__item} data-scroll data-scroll-speed={-1}>
            <div className={styles.el__image}>
              <PatternSvg />
            </div>
          </div>
          {images.map((image, index) => {
            return (
              <div
                key={image.id}
                className={styles.el__item}
                data-scroll
                data-scroll-speed={speeds[index]}
              >
                <div className={styles.el__image}>
                  <Img {...image} />
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </Section>
  )
}

ImageLayout.defaultProps = {
  uid: undefined,
  images: [],
}

ImageLayout.propTypes = {
  uid: PropTypes.string,
  images: PropTypes.array,
}

export { ImageLayout }
