import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Ticker from 'react-ticker'
import PageVisibility from 'react-page-visibility'
import * as styles from './Marquee.module.scss'

const Marquee = ({ children }) => {
  const [pageIsVisible, setPageIsVisible] = useState(true)
  const handleVisibilityChange = (isVisible) => setPageIsVisible(isVisible)

  return (
    <PageVisibility onChange={handleVisibilityChange}>
      {pageIsVisible && (
        <div className={styles.el}>
          <Ticker>
            {() => <div className={styles.el__part}>{children}</div>}
          </Ticker>
        </div>
      )}
    </PageVisibility>
  )
}

Marquee.defaultProps = {
  children: undefined,
}

Marquee.propTypes = {
  children: PropTypes.node,
}

export { Marquee }
