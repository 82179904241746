import React from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import { Img } from '@/components/Img'
import { Section } from '@/components/Section'
import { Marquee } from '@/components/Marquee'
import { VideoBg } from '@/components/VideoBg'
import { RichText } from '@/components/RichText'
import * as styles from './Contact.module.scss'

const Contact = ({ uid, heading, details, openingHours, video, image }) => {
  const { ref } = useInView({
    threshold: 0,
    onChange: (inView) => {
      if (inView && uid) {
        window.history.pushState(null, null, `#${uid}`)
      }
    },
  })
  return (
    <Section className={styles.contact}>
      <div className={styles.container}>
        <div className={styles.intersection} id={uid} ref={ref} />
        <div className={styles.inner} id="direction">
          {video && (
            <div className={styles.header}>
              {image && (
                <div className={styles.image} data-scroll data-scroll-speed="1">
                  <div className={styles.image__inner}>
                    <div className={styles.image__sizer} />
                    <Img {...image} fillContainer objectFit="cover" />
                  </div>
                </div>
              )}
              <div className={styles.video}>
                <div className={styles.video__sizer} />
                <VideoBg url={video} />
              </div>
              {heading && (
                <div className={styles.marquee}>
                  <div
                    className={styles.marquee__inner}
                    data-scroll
                    data-scroll-direction="horizontal"
                    data-scroll-speed="6"
                    data-scroll-target="#direction"
                  >
                    <Marquee>{heading}</Marquee>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className={styles.details}>
            <div className={styles.contactInfo}>
              <RichText className={styles.contactInfo__richText}>
                {details}
              </RichText>
            </div>
            <div className={styles.openingHours}>
              {openingHours.map((item) => {
                if (item.hours !== undefined) {
                  return (
                    <div key={item.id} className={styles.openingHours__row}>
                      <div>{item.day}</div>
                      <div>{item.hours}</div>
                    </div>
                  )
                }
                return null
              })}
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

Contact.defaultProps = {
  openingHours: [],
}

Contact.propTypes = {
  uid: PropTypes.string,
  heading: PropTypes.string,
  details: PropTypes.node,
  openingHours: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  video: PropTypes.string,
  image: PropTypes.object,
}

export { Contact }
