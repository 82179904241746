// extracted by mini-css-extract-plugin
export var Callout = "Callout-module--Callout--x7kAs";
export var aniSpin = "Callout-module--ani-spin--xZn0z";
export var black = "#000";
export var footer__btn = "Callout-module--footer__btn--AAZzr";
export var footer__text = "Callout-module--footer__text--NZGg7";
export var footer__wrap = "Callout-module--footer__wrap--YsHMo";
export var largeDesktop = "1400px";
export var largePhone = "480px";
export var mediumDesktop = "1300px";
export var smallDesktop = "1100px";
export var smallPhone = "320px";
export var tabletLandscape = "1024px";
export var tabletPortrait = "768px";
export var white = "#fff";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";