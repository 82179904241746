import React from 'react'
import PropTypes from 'prop-types'
import { Img } from '@/components/Img'
import { RichText } from '@/components/RichText'
import { Btn } from '@/components/Btn'
import * as styles from './Thumb.module.scss'

const Thumb = ({ image, heading, description, link }) => {
  return (
    <div>
      <div className={styles.grid}>
        <div className={styles.image} data-scroll data-scroll-speed="4">
          <Img {...image} />
        </div>
        <div className={styles.text}>
          <h3 className={styles.heading}>{heading}</h3>
          <RichText>{description}</RichText>
          {link && (
            <Btn
              to={link.url}
              className={styles.btn}
              innerClassName={styles.btn__inner}
            >
              {link.title}
            </Btn>
          )}
        </div>
      </div>
    </div>
  )
}

Thumb.propTypes = {
  image: PropTypes.object,
  heading: PropTypes.node,
  description: PropTypes.node,
  link: PropTypes.object,
}

export { Thumb }
