import React, { useEffect } from 'react'
import classNames from 'classnames'
import LazyLoad from 'vanilla-lazyload'
import PropTypes from 'prop-types'
import * as styles from './Img.module.scss'

const IS_BROWSER = typeof document !== 'undefined'

const Img = ({
  src,
  srcset,
  sizes,
  alt,
  placeholderSrc,
  objectFit,
  objectPosition,
  fillContainer,
  aspectRatio,
  isLazy,
  isResponsive,
}) => {
  // configure lazy loading
  const lazyClass = isLazy ? 'lazy' : null

  if (isLazy) {
    // only initialize lazy loading one time for the app
    if (IS_BROWSER && !document.lazyLoadInstance) {
      document.lazyLoadInstance = new LazyLoad({
        elements_selector: `.${lazyClass}`,
        callback_loaded: (el) => {
          el.parentElement.setAttribute('data-loaded', 'true')
        },
      })
    }
  }

  // update lazyLoad after first rendering of every image
  useEffect(() => {
    if (IS_BROWSER && isLazy) {
      document.lazyLoadInstance.update()
    }
  }, [isLazy, isResponsive])

  const containerStyle = {
    paddingTop: aspectRatio && !fillContainer ? `${100 / aspectRatio}%` : null,
  }

  const imgStyle = { objectFit, objectPosition }

  return (
    <div
      className={styles.el}
      data-fill-container={fillContainer}
      data-loaded={!isLazy}
      style={containerStyle}
    >
      {isLazy ? (
        <img
          alt={alt || ''}
          className={classNames(styles.img, lazyClass)}
          data-src={src}
          data-srcset={isResponsive ? srcset : null}
          data-sizes={isResponsive ? sizes : null}
          style={imgStyle}
          data-fill-container={aspectRatio ? true : null}
        />
      ) : (
        <img
          alt={alt || ''}
          className={styles.img}
          src={src}
          srcSet={isResponsive ? srcset : null}
          sizes={isResponsive ? sizes : null}
          style={imgStyle}
          data-fill-container={aspectRatio ? true : null}
        />
      )}
      {placeholderSrc && (
        <img src={placeholderSrc} alt="" className={styles.placeholder} />
      )}
    </div>
  )
}

Img.defaultProps = {
  alt: '',
  isLazy: true,
  isResponsive: true,
}

Img.propTypes = {
  alt: PropTypes.string,
  aspectRatio: PropTypes.number,
  fillContainer: PropTypes.bool,
  isLazy: PropTypes.bool,
  isResponsive: PropTypes.bool,
  objectFit: PropTypes.string,
  objectPosition: PropTypes.string,
  placeholderSrc: PropTypes.string,
  sizes: PropTypes.string,
  src: PropTypes.string,
  srcset: PropTypes.string,
}

export { Img }
